























































import { Component } from 'vue-property-decorator'
import { PageBase } from '@/core/models/shared';
import { MedicaoService } from '@/core/services/residuo';

@Component
export default class ImprimirContaGerencialDespesa extends PageBase{

  item: any[] = [];
  service: MedicaoService = new MedicaoService();
  loading: boolean = false;

  filtro: any = {
    dataInicio: null,
    dataFim: null,
    clienteId: null,
    contratoId: null,
    osNumero: null,
    transportadorId: null
  }

  created(){
    this.filtro.dataInicio = this.$route.query.dataInicio;
    this.filtro.dataFim = this.$route.query.dataFim;
    this.filtro.clienteId = this.$route.query.clienteId;
    this.filtro.contratoId = this.$route.query.contratoId;
    this.filtro.osNumero = this.$route.query.osNumero;
    this.filtro.transportadorId = this.$route.query.transportadorId;
  }

  mounted() {
    this.Carregar();
  }

  Carregar(){
    this.loading = true;
    this.service.PrevisaoRelacaoGastoLocatarioGrupo(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.transportadorId).then(
      res => {
        this.item = res.data;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')
      }
    ).finally(() => { this.loading = false; })

  }
}
